import React from "react"
import Layout from "../components/Layout"

const AdminPage = () => (
  <Layout>
    <h1>This is a fake admin page</h1>
    <p>Welcome to my Gatsby site.</p>
  </Layout>
)

export default AdminPage